<template>
<div>
    <DollarSuccess />
</div>
</template>

<script>
import DollarSuccess from '../../components/dashboard/dollarAsset/DollarSuccess.vue'
export default {
    components: {
        DollarSuccess
    }
}
</script>

