    <template>
  <div>
    <Wrapper>
      <Sidebar slot="sidebar" />
      <Navbar slot="navbar" />
      <Footer slot="footer" />
      <div slot="content">
        <!-- <input type="text" v-model="filters.name.value"> -->
        <!-- <div class="pre-loader text-center" v-if="preLoader">
                    <span class="loader"></span>
        </div>-->
       

        <div class="mt-4"></div>

        <div class="invest-container">
        

          <div style="max-width: 500px;
    margin: 0px auto;
    min-height: 500px;
    
    display: flex;
    align-items: center;">
         

             <div style="border:1px solid #BDBDBD; border-radius: 10px; min-height:400px; padding:20px; background:#fff;" class="mt-4 success-div">
                 <div style="min-height:300px;display:flex; justify-content:center;  flex-direction:column">
  <div style="position:relative;" class="text-center">
                    <img src="../../../assets/images/booksuccess.png" height="80">
                  </div>
                  <div class="display-div text-center mb-3 mt-3">
                    <p style=" color:#828282; font-size:18px" class="mr-3" >Your ${{fundAmount}}  Euro bond has been added to your portfolio</p>
                  </div>

                 </div>
                

         
                   <div class="mt-2 point-cursor"  style=" min-height:100px;display:flex; justify-content:center; align-items:center; border-top: 0.5px solid #E0E0E0;" @click="sendToPortfolio">
<p style="color:#0094FF; font-size:18px">See in Portfolio</p>  
</div>
                </div>
                </div>
        </div>
      </div>
    </Wrapper>
  </div>
</template>

<script>
import Navbar from "../../partials/Navbar";
import Sidebar from "../../partials/Sidebar";
import Footer from "../../partials/Footer";
import Wrapper from "../../partials/Wrapper";
import trans from "../../../../translations";

import { commonJs } from "../mixins/commonJs.js";

import { mapState } from "vuex";

import moment from "moment";
import http from "../../../http/index";
import api from "../../../apis/index";
export default {
  mixins: [commonJs],
  data() {
    return {
      tran: {},
     fundAmount:''
    };
  },
  methods: {
    sendToMarketPlace() {
      this.$router.push("/market");
    },
    sendToPortfolio() {
      this.$router.push("/portfolio");
    },
   
  },
  components: {
    Navbar,
    Footer,
    Sidebar,
    Wrapper,
  },

  computed: {
    ...mapState({
      userBal: (state) => state.auth.userProfileInfo.balance,
     
    }),
  
 
  },
  mounted() {
    let heading = {
          main: this.tran.euro_bond
    };
    this.fundAmount = this.$route.query.fundAmount
  },
};
</script>

<style scoped>
.invest-container {
  background: #fff;
  min-height: 80vh;
  margin: 5%;
  margin-top: 2%;
  border-radius: 10px;
}

.tenor-div {
  cursor: pointer;
  transition: 0.4s;
}

.grey-text {
  color: #828282;
}




@media (max-width: 1500px) {
  .invest-container {
    zoom: 85%;
    min-height: 90vh;
  }
}

@media (max-width: 767px) {
  .invest-container {
    background: transparent !important;
  }
  .success-div{

      border:1px solid #fff !important;
  }
}
</style>
